<template>
  <section class="buying-on-apple">
    <h1>Проблема с покупкой локаций на устройствах Apple</h1>
    <ol class="buying-on-apple__list">
      <li class="buying-on-apple__item">
        <h2 @click="login" class="buying-on-apple__title">Авторизуйтесь на сайте</h2>
        <!--        <span v-if="!user" @click="login" class="buying-on-apple__login">Жми для регистрации</span>-->
        <p>Убедитесь, что и на сайте, и в приложении у вас используется одна и та же учетная запись.</p>
        <p>
          Если у вас нет учетной записи,
          <span @click="login" class="buying-on-apple__login">зарегистрируйтесь</span>, чтобы не потерять
          доступ к своим локациям
        </p>
      </li>
      <li class="buying-on-apple__item">
        <h2>Купите локацию</h2>
        <p>Выберите наиболее удобный для вас способ оплаты</p>
      </li>
      <li class="buying-on-apple__item">
        <h2>Путешествуйте и изучайте новые локации</h2>
        <div class="buying-on-apple__btn">
          <router-link :to="{ name: 'home', hash: '#subjects' }" class="btn btn--main"
            >Перейти к покупкам</router-link
          >
        </div>
      </li>
    </ol>
  </section>
</template>

<script>
import LoginPhoneModal from "components/modals/components/LoginPhoneModal.vue";

export default {
  name: "BuyingOnApple",
  methods: {
    login() {
      this.$store.state._modals.push({
        component: LoginPhoneModal,
      });
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
};
</script>

<style lang="stylus">
.buying-on-apple {
  display grid
  padding 30px
  padding-top 0
  width 100%

  &__title{
    cursor: pointer;
    text-decoration underline
    color var(--green)
  }

  h1 {
    color var(--dark)
    +below(560px) {
      font-size 1.725em
    }
  }

  &__login{
    cursor: pointer;
    text-decoration underline
  }

  &__list {
    display flex
    flex-direction column
    padding 0
    margin 0
    +below(560px) {
      h2 {
        font-size 1.225em
      }
    }
  }
  &__item{
    .btn{
      padding: 30px
    }
  }

  &__btn{
    +below(560px) {
      width 100%
      justify-content center
      display flex
    }
  }
}
</style>
